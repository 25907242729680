module services {
    'use strict';
    export module applicationcore {

        interface IConfirmBox {
            title: string;
            message: string;
        };

        export class GeneralService implements interfaces.applicationcore.IGeneralService {
            static $inject = ["$resource", "$rootScope", "$q", "$uibModal", "$timeout", "$interval"];

            constructor(private $resource: ng.resource.IResourceService, private $rootScope: interfaces.applicationcore.IRootScope,
                private $q: angular.IQService, private $uibModal: angular.ui.bootstrap.IModalService,
                private $timeout: ng.ITimeoutService, $interval) {
            }

            displayMessageHandler(messageHandler: interfaces.applicationcore.IMessageHandler, controller: interfaces.applicationcore.IModalController): void {

                var scope = controller || this.$rootScope;
                if (messageHandler.Messages) {
                    scope.Messages = [];
                }
                
                angular.forEach(messageHandler.Messages, (o) => {
                    angular.forEach(o.MessageItems, (n) => {
                        scope.Messages.push(n);
                        if (n.TypeEnum === 3) {
                            this.$timeout(() => {
                                _.remove(scope.Messages, (z) => {
                                    return z === n;
                                });
                            }, 5000);
                        }
                    });
                });
            }

            displayYesNoConfirmation(title: string, message: string){
                var defer = this.$q.defer<boolean>();

                this.$uibModal.open({
                    animation: true,
                    size: 'sm',
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">{{title}}</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    {{message}}
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">Yes</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">No</button>
                                </div>
                            </div>`,
                    controller: function ($scope, items: IConfirmBox, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) {
                        $scope.title = items.title;
                        $scope.message = items.message;

                        $scope.ok = function () {
                            $uibModalInstance.close(true);
                        };

                        $scope.cancel = function () {
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    resolve: {
                        items: function () {
                            return {
                                title: title,
                                message: message
                            };
                        }
                    }
                }).result.then((data) => {
                    if (data) {
                        defer.resolve(data);
                    } else {
                        defer.resolve(false);
                    }
                    }, () => {
                        defer.resolve(false);
                });

                return defer.promise;
            }

            displayConfirmationBox(title: string, message: string) {
                var defer = this.$q.defer<boolean>();

                this.$uibModal.open({
                    animation: true,
                    size: 'sm',
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">{{title}}</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    {{message}}
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>`,
                    controller: function ($scope, items: IConfirmBox, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) {
                        $scope.title = items.title;
                        $scope.message = items.message;

                        $scope.ok = function () {
                            $uibModalInstance.close(true);
                        };

                        $scope.cancel = function () {
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    resolve: {
                        items: function () {
                            return {
                                title: title,
                                message: message
                            };
                        }
                    }
                }).result.then((data) => {
                    if (data) {
                        defer.resolve(data);
                    } else {
                        defer.resolve(false);
                    }
                    }, () => {
                        defer.resolve(false);
                });

                return defer.promise;
            }

            displayMessageBox(title: string, message: string) {
                this.$uibModal.open({
                    animation: true,
                    size: 'sm',
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">{{title}}</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    {{message}}
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                </div>
                            </div>`,
                    controller: function ($scope, items: IConfirmBox, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) {
                        $scope.title = items.title;
                        $scope.message = items.message;

                        $scope.ok = function () {
                            $uibModalInstance.close(true);
                        };
                    },
                    resolve: {
                        items: function () {
                            return {
                                title: title,
                                message: message
                            };
                        }
                    }
                });
            }

            displayMessage(message: string, type: Enum.EnumMessageType, controller: interfaces.applicationcore.IModalController): void {

                var scope = controller || this.$rootScope;

                scope.Messages = [];

                scope.Messages.push({
                    LocaleDescription: message,
                    TypeEnum: type
                });
                if (type === 3) {
                    this.$timeout(() => {
                        scope.Messages = [];
                    }, 5000);
                }
            }

            displayLogoutConfirmationBox(title: string, message: string) {
                var defer = this.$q.defer<boolean>();

                this.$uibModal.open({
                    animation: true,
                    size: 'sm',
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">{{title}}</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <p>{{message}}</p>
                                    <p>You have {{counter}} seconds left to respond.</p>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>`,
                    controller: function ($scope, items: IConfirmBox, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, $timeout) {
                        $scope.title = items.title;
                        $scope.message = items.message;
                        $scope.counter = 60;

                        $scope.interval = setInterval(function () {
                            $timeout(() => {
                                $scope.counter -= 1;
                                if($scope.counter == 0)
                                {
                                    clearInterval($scope.interval);
                                    $uibModalInstance.dismiss(false);
                                }
                            });
                        }, 1000);

                        $scope.ok = function () {
                            clearInterval($scope.interval);
                            $uibModalInstance.close(true);
                        };

                        $scope.cancel = function () {
                            clearInterval($scope.interval);
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    resolve: {
                        items: function () {
                            return {
                                title: title,
                                message: message
                            };
                        }
                    }
                }).result.then((data) => {
                    if (data) {
                        defer.resolve(data);
                    } else {
                        defer.resolve(false);
                    }
                    }, () => {
                        defer.resolve(false);
                });

                return defer.promise;
            }

            handleValue(value): string {
                if(value) {
                    if (value.Display) {
                        return value.Display;
                    }
                    if(value.date) {
                        return value.format('YYYY/MM/DD');
                    }
                    return value;
                } else {
                    return value;
                }
            }
        }
    }
    angular.module("app").service("generalService", services.applicationcore.GeneralService);
}